import React, { useState, useEffect, useMemo, useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider, useDispatch, connect } from "react-redux";
import { storeTWO } from "./redux/store";
import jwt_decode from "jwt-decode";
import {
  authCheck,
  getCurrentUserDoctorProfile,
  tokenData,
} from "./redux/slices/user";
import Home from "./components/Home";
import Calendar from "./components/calendar/Calendar";
import "./assets/stylesheets/app.css";
import Patients from "./components/patients/PatientsList";
import PatientProfile from "./components/patients/PatientProfile";
import NewPatient from "./components/patients/NewPatient";
import Login from "./components/users/Login";
import Signup from "./components/users/Signup";
import ViewCitation from "./components/citations/ViewCitation";
import CompleteAppointment from "./components/citations/CompleteAppointment";
import TreatmentsList from "./components/treatments/TreatmentsList";
import NewTreatment from "./components/treatments/NewTreatment";
import Config from "./components/config/Config";
import NewProduct from "./components/products/NewProduct";
import EditProduct from "./components/products/EditProduct";
import ProductsList from "./components/products/ProductsList";
import DoctorsList from "./components/doctors/DoctorsList";
import NewDoctor from "./components/doctors/NewDoctorStepper";
import EditCompany from "./pages/Company/Edit";
import RemindersList from "./components/reminders/RemindersList";
import NewPatientBooking from "./components/citations/booking/NewPatientBooking";
import FourthStepNewPatient from "./components/citations/booking/steps/FourthStepNewPatient";
import ExistingPatientBooking from "./components/citations/booking/ExistingPatientBooking";
import InvoicingMachine from "./components/invoices/InvoicingMachine";
import { InvoicesList } from "./components/invoices/InvoicesList";
import HomePatient from "./components/HomePatient";
import HomeSecretary from "./components/HomeSecretary";
import CalendarPatient from "./components/calendar/CalendarPatients";
import footer from "./components/homepageViews/footer";
import Zunge from "./components/citations/completeappointment/zunge/Zunge";
import Pulse from "./components/citations/completeappointment/pulse/Pulse";
import Forgot from "./components/users/ForgotPassword";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import InsurancesList from "./components/insurances/InsurancesList";
import DiagnosisList from "./components/diagnosis/DiagnosisList";
import QuizList from "./components/quiz/QuizList";
import Quiz from "./components/quiz/quizBuilder/Quiz";
import AnswerQuiz from "./components/quiz/quizForPatient/AnswerQuiz";
import PatientAnswers from "./components/quiz/quizForPatient/PatientAnswers";
import ProfileSettings from "./components/users/profile/ProfileSettings";
import AdittionalsList from "./components/adittionals/AdittionalsList";
import NewAdittional from "./components/adittionals/NewAdittional";
import UploadFile from "./components/citations/completeappointment/UploadFile";
import Error from "./components/Error";
import Herbs from "./components/herbs/HerbsList";
import ViewEditHerb from "./components/herbs/ViewEditHerb";
import GetAuthToken from "./utils/GetAuthToken";
import EditTreatment from "./components/treatments/EditTreatment";
import EditAdittional from "./components/adittionals/EditAdittional2";
import PatientInvoices from "./components/invoices/invoicesPatient/PatientInvoices";
import PatientNotFound from "./components/PatientNotFound";
import { datadogRum } from "@datadog/browser-rum";
import NewHerb from "./components/herbs/NewHerb";
import { getOneCompany, getOneCompanyBySlug } from "./redux/slices/company";
import CircularProgress from "@material-ui/core/CircularProgress";
import Blend from "./pages/Blend/index";
import Pharmacy from "./pages/Pharmacy/index";
import Prescription from "./pages/Prescription";
import Dashboard from "./components/dashboard/index";
import TaxRateList from "./components/taxRates/TaxRatesList";
//import generateMaterialUiTheme from './utils/generateMaterialUiTheme';
//import { ThemeProvider } from '@mui/material/styles';
import { getCustomTheme } from "./utils/theme";

const NoAccess = () => {
  return <Error />;
};

const Component = ({ company, companyLoading }) => {
  const history = createBrowserHistory();
  const dispatch = useDispatch();
  const navbarRef = useRef(null);

  const environment =
    process.env.NODE_ENV !== "production" ? "development" : "production";

  const [validToken, setValidToken] = useState();
  const [loading, setLoading] = useState(true);
  const [decoded, setDecoded] = useState();

  const [admin, setAdmin] = useState(false);
  const [therapist, setTherapist] = useState(false);
  const [secretary, setSecretary] = useState(false);

  const [userId, setUserId] = useState();
  const [patientId, setPatientId] = useState();

  const subdomain = useMemo(() => {
    return window.location.hostname.split(".")[0];
  }, []);

  /*   const theme = useMemo(() => {
    return generateMaterialUiTheme(company);
  }, [company]) */

  const validateToken = async () => {
    const tokenString = localStorage.getItem("token");

    if (tokenString) {
      const userToken = JSON.parse(tokenString);
      var url = process.env.REACT_APP_API_URL + "/users/getAuthUser";

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: "Bearer " + userToken.token,
        },
        redirect: "follow",
        body: JSON.stringify(userToken.token),
      };

      await fetch(url, requestOptions).then((response) => {
        if (response.ok) {
          setValidToken(userToken?.token);
          decodeToken(userToken?.token);
          setLoading(false);
        } else {
          localStorage.clear();
          setLoading(false);
        }
      });
    } else {
      localStorage.clear();
      setLoading(false);
    }
  };

  const decodeToken = (actualToken) => {
    validToken ? setDecoded(jwt_decode(actualToken)) : <></>;
    const decodedToken = jwt_decode(actualToken);
    dispatch(getOneCompany({ id: decodedToken?.company_ids[0] }));
    dispatch(authCheck(decodedToken));
    decodedToken.roles.isDoctor &&
      dispatch(getCurrentUserDoctorProfile({ user_id: decodedToken.user_id }));
    setAdmin(decodedToken.admin);
    setSecretary(decodedToken.secretary);
    setTherapist(decodedToken.doctor);
    setUserId(decodedToken.user_id);
    setPatientId(decodedToken.patient_id);
  };

  const deleteToken = () => {
    localStorage.removeItem("token");
    setValidToken(undefined);
    window.location.reload();
  };

  const setAuth0Token = async () => {
    const encrypted = await GetAuthToken();
    dispatch(tokenData(encrypted.access_token));
  };

  useEffect(() => {
    validateToken();
    // validToken ? getAuthUser(validToken) : <></>

    // data dog
    if (environment === "production") {
      datadogRum.init({
        applicationId: "93501f77-0a0d-4488-a8cb-570792a82050",
        clientToken: "pub8ff1c370e508b1b794447bc5ca93963e",
        site: "datadoghq.eu",
        service: "tcm-assist",
        env: "production",
        version: "1.0.0",
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        actionNameAttribute: "id",
        defaultPrivacyLevel: "mask-user-input",
      });
      datadogRum.startSessionReplayRecording();
    }
  }, [validToken]);

  useEffect(() => {
    dispatch(getOneCompanyBySlug({ slug: subdomain }));
  }, [subdomain]);

  useEffect(() => {
    const url = window.location.href ? window.location.href : "";
    if (url.includes("qi-point")) {
      getCustomTheme(
        company?.primary_color ? company?.primary_color : "#8754ba",
        company?.secondary_color ? company?.secondary_color : "#e9d3ff"
      );
    } else {
      getCustomTheme(
        company?.primary_color ? company?.primary_color : "#666666",
        company?.secondary_color ? company?.secondary_color : "#8c8c8c"
      );
    }
  }, [company]);

  if (companyLoading) {
    <CircularProgress color="secondary" />;
  }

  if (loading) {
    return <CircularProgress color="secondary" />;
  }

  if (!validToken) {
    switch (window.location.pathname) {
      case "/neuertermin":
        return <NewPatientBooking />;
      case "/login":
        return (
          <Login setValidToken={setValidToken} setAuth0Token={setAuth0Token} />
        );
      case "/forgot-password":
        return <Forgot />;
      default:
        return (
          <Login setValidToken={setValidToken} setAuth0Token={setAuth0Token} />
        );
    }
  }

  return (
    // <ThemeProvider theme={theme}></ThemeProvider>
    <Router history={history}>
      <div className="body">
        <Sidebar deleteToken={deleteToken} />
        <Navbar
          deleteToken={deleteToken}
          history={history}
          navbarRef={navbarRef}
        />
        <Switch>
          <Route
            path="/citation/:id"
            component={
              admin || secretary || therapist ? ViewCitation : NoAccess
            }
          />
          <Route
            path="/patients-profile/:id"
            component={
              admin || secretary || therapist ? PatientProfile : NoAccess
            }
          />
          <Route
            path="/completeappointment/:id"
            component={admin || therapist ? CompleteAppointment : NoAccess}
          />
          <Route path="/user-settings" exact component={ProfileSettings} />
          <Route
            path="/patients"
            component={admin || secretary || therapist ? Patients : NoAccess}
          />
          <Route
            path="/new-patient"
            component={admin || secretary || therapist ? NewPatient : NoAccess}
          />
          <Route
            path="/treatments"
            component={
              admin || secretary || therapist ? TreatmentsList : NoAccess
            }
          />
          <Route
            path="/newtreatment"
            component={
              admin || secretary || therapist ? NewTreatment : NoAccess
            }
          />
          <Route
            path="/edit-treatment/:id"
            component={
              admin || secretary || therapist ? EditTreatment : NoAccess
            }
          />
          <Route
            path="/adittionals"
            component={
              admin || secretary || therapist ? AdittionalsList : NoAccess
            }
          />
          <Route
            path="/newadittional"
            component={
              admin || secretary || therapist ? NewAdittional : NoAccess
            }
          />
          <Route
            path="/edit-adittional/:id"
            component={
              admin || secretary || therapist ? EditAdittional : NoAccess
            }
          />
          <Route path="/config" component={admin ? Config : NoAccess} />
          <Route
            path="/products"
            component={
              admin || secretary || therapist ? ProductsList : NoAccess
            }
          />
          <Route
            path="/insurances"
            component={
              admin || secretary || therapist ? InsurancesList : NoAccess
            }
          />
          <Route
            path="/new-product"
            component={admin || secretary || therapist ? NewProduct : NoAccess}
          />
          <Route
            path="/edit-product/:id"
            component={admin || secretary || therapist ? EditProduct : NoAccess}
          />
          <Route
            path="/doctors"
            component={admin || secretary || therapist ? DoctorsList : NoAccess}
          />
          <Route path="/company" component={admin ? EditCompany : NoAccess} />
          <Route
            path="/newdoctor"
            component={admin || secretary || therapist ? NewDoctor : NoAccess}
          />
          <Route
            path="/reminders"
            component={
              admin || secretary || therapist ? RemindersList : NoAccess
            }
          />
          <Route
            path="/diagnosis"
            component={
              admin || secretary || therapist ? DiagnosisList : NoAccess
            }
          />
          <Route path="/dashboard" component={admin ? Dashboard : NoAccess} />
          <Route path="/tax" component={admin ? TaxRateList : NoAccess} />
          <Route path="/invoices">
            {admin || secretary ? (
              <InvoicesList />
            ) : (
              <PatientInvoices
                patientId={patientId}
                deleteToken={deleteToken}
                history={history}
              />
            )}
          </Route>
          <Route
            path="/create-invoice/:id"
            component={admin || secretary ? InvoicingMachine : NoAccess}
          />
          <Route
            path="/create-quiz"
            component={admin || secretary || therapist ? Quiz : NoAccess}
          />
          <Route
            path="/quizzes"
            component={admin || secretary || therapist ? QuizList : NoAccess}
          />
          <Route path="/footer" component={footer} />
          <Route
            path="/zunge"
            component={admin || secretary || therapist ? Zunge : NoAccess}
          />
          <Route
            path="/pulse"
            component={admin || secretary || therapist ? Pulse : NoAccess}
          />
          <Route
            path="/login"
            exact
            render={() => <Login setValidToken={setValidToken} />}
          />
          <Route path="/sign-up" exact component={Signup} />
          <Route
            path="/upload-file"
            exact
            component={admin || secretary || therapist ? UploadFile : NoAccess}
          />
          <Route path="/answer-quiz/:id" exact component={AnswerQuiz} />
          <Route path="/patient-answers/:id" exact component={PatientAnswers} />
          <Route path="/existingpatientcitation">
            <ExistingPatientBooking navbarRef={navbarRef} />
          </Route>
          <Route path="/patient-calendar" component={CalendarPatient} />
          <Route path="/patientcitation/:id" component={FourthStepNewPatient} />

          {/* Herbs */}
          <Route
            path="/herbs"
            component={admin || therapist ? Herbs : NoAccess}
          />
          <Route
            path="/new-herb"
            component={admin || therapist ? NewHerb : NoAccess}
          />
          <Route
            path="/herb/:action/:id"
            component={admin || therapist ? ViewEditHerb : NoAccess}
          />

          <Route
            exact
            path="/calendar"
            component={
              userId !== undefined ? (
                admin || therapist ? (
                  Calendar
                ) : secretary ? (
                  Calendar
                ) : (
                  Error
                )
              ) : (
                <>Loading data...</>
              )
            }
          />
          <Route exact path="/">
            <Provider store={storeTWO}>
              {userId !== undefined ? (
                <>
                  {admin || therapist ? (
                    <Home userId={userId} deleteToken={deleteToken} />
                  ) : (
                    <>
                      {secretary ? (
                        <HomeSecretary userId={userId} />
                      ) : (
                        patientId !== undefined && (
                          <HomePatient
                            userId={userId}
                            patientId={patientId}
                            navbarRef={navbarRef}
                          />
                        )
                      )}
                    </>
                  )}
                </>
              ) : (
                <>Loading data...</>
              )}
            </Provider>
          </Route>

          <Route
            path="/blend"
            render={(props) => (
              <Blend admin={admin} therapist={therapist} props={{ ...props }} />
            )}
          />

          <Route
            path="/pharmacy"
            render={(props) => (
              <Pharmacy
                admin={admin}
                therapist={therapist}
                props={{ ...props }}
              />
            )}
          />

          <Route
            path="/prescription"
            render={(props) => (
              <Prescription
                admin={admin}
                therapist={therapist}
                props={{ ...props }}
              />
            )}
          />

          {/* Error 404: Patient not found */}
          <Route exact path="/404" component={PatientNotFound} />
        </Switch>
      </div>
    </Router>
    // </ThemeProvider>
  );
};

const state = ({ companyStore }) => {
  const {
    data: company,
    loading: companyLoading,
    error: companyError,
    success: companySuccess,
  } = companyStore.one;

  return {
    company,
    companyLoading,
    companyError,
    companySuccess,
  };
};

export default connect(state)(Component);
